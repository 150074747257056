<template>
	<!-- 账单记录 -->
	<div class="platform-fund-bill">
		<div class="el-content pfb-top mb12">
			<a-space>
				<a-range-picker v-model:value="search.time"/>
				<a-button type="primary" @click="getBillRecord(1,info.limit)">筛选</a-button>
                <a-button @click="exportBill">导出账单</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">账单记录</div>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'操作对象',dataIndex:'gold',slots:{customRender:'gold'}},
				{title:'类型',dataIndex:'type',slots:{customRender:'type'}},
				{title:'用户',dataIndex:'user',slots:{customRender:'user'}},
				{title:'交易单号',dataIndex:'trade.order_number'},
				{title:'金额(元)',dataIndex:'change',slots:{customRender:'change'}},
				{title:'名称',dataIndex:'description'},
				{title:'交易时间',dataIndex:'create_time'},
			]" >
				<template #gold="{record}">
					<div v-if="record.gold.type == 0" style="display: flex;align-items: center;">
						<a-avatar :src="record.gold.program.logo"/>
						<div>
							<div><span class="f12">应用名称：</span>{{record.gold.program.program_name}}</div>
							<div><span class="f12">APPID：</span>{{record.gold.program.program_id}}</div>
						</div>
					</div>
					<div v-if="record.gold.type == 1" style="display: flex;align-items: center;">
						<a-avatar :src="record.gold.user.avatar_url"/>
						<div>
							<div><span class="f12">用户昵称：</span>{{record.gold.user.nickname}}</div>
							<div><span class="f12">联系电话：</span>{{record.gold.user.mobile}}</div>
						</div>
					</div>
					<div v-if="record.gold.type == 2" style="display: flex;align-items: center;">
						<a-avatar :src="record.gold.shop.logo"/>
						<div>
							<div><span class="f12">店铺名称：</span>{{record.gold.shop.name}}</div>
							<div><span class="f12">联系电话：</span>{{record.gold.shop.mobile}}</div>
						</div>
					</div>
				</template>
				<template #type="{record}">
					<span v-if="record.type == 1">增加可用</span>
					<span v-if="record.type == 2">减少可用</span>
					<span v-if="record.type == 3">增加冻结</span>
					<span v-if="record.type == 4">减少冻结</span>
				</template>
				<template #user="{record}">
					<div style="display: flex;align-items: center;">
						<img :src="record.trade.user_avatar" alt="" style="width: 30px;height: 30px;border-radius: 50%;margin-right: 4px;">
						<span>{{record.trade.user_name}}</span>
					</div>
				</template>
				<template #change="{record}">
					<span v-if="record.type == 1 || record.type == 3">+</span>
					<span v-else>-</span>
					<span>{{record.change}}</span>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBillRecord(info.page,e)}"
					@change="(e)=>{getBillRecord(e,info.limit)}"
				/>
			</div>
		</div>

	</div>
</template>

<script>
import userModel from '@/api/user.js'
import { reactive, toRefs } from 'vue'
import siteinfo from '@/util/global'
export default{
	setup(){
		const _d = reactive({
			search:{ time:[], },
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			money:null,
		})
		userModel.getProgramMoney(res=>{
			_d.money = res
			getBillRecord(1,_d.info.limit)
		})
		function getBillRecord(page,limit){
			userModel.getBillRecord(page,limit,{money_id:_d.money.id,..._d.search},res=>_d.info = {limit,...res})
		}

		function exportBill(){
		    userModel.getBillRecord(_d.info.page,_d.info.limit,{money_id:_d.money.id,export:1,..._d.search},()=>{})
        }

		return{
			...toRefs(_d),
			getBillRecord,
            exportBill
		}
	},
}
</script>

<style lang="scss">
.pfb-top{
	width: 100%;
	background: #fff;
	padding-bottom: 24px;

	&-money{
		width:90%;
		height: 60px;
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #ebeef5;
		margin-bottom: 24px;

		&-li{
			width: 400px;
			height: 100%;

			>div:first-child{
				color: #999;
				font-size: 14px;
				margin-bottom: 8px;
			}
			.pfbt-price{
				span{
					font-size: 28px;
				}
			}
		}
	}
}
</style>
